<template>
  <div>
    <v-row>
      <v-col col="12">
        <v-card elevation="10">
          <v-toolbar
            dense
            color="#003d6a"
            elevation="0"
            dark
            style="z-index: 9"
          >
            <v-toolbar-title>Kuponlar</v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="d-flex justify-content-end" style="min-width: 550px">
              <input
                type="text"
                v-model="searchFilterInput"
                class="form-control mr-3"
                style="max-width: 250px"
                placeholder="Arama..."
                v-on:keyup="onFilterTextBoxChanged()"
              />
              <RoleProvider slug="COUPONS_CREATE">
                <div slot-scope="{ canItPass }">
                  <CButton
                    color="light"
                    variant="outline"
                    size="sm"
                    @click="dialog = true"
                    class="py-2 px-3 font-weight-bold"
                    v-if="canItPass"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                      class="mr-2"
                    />Yeni Ekle
                  </CButton>
                </div>
              </RoleProvider>
            </div>
          </v-toolbar>

          <CCardBody v-if="!gridLoading" class="p-0">
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-alpine"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :detailCellRendererParams="detailCellRendererParams"
              @rowDoubleClicked="editItem"
              @grid-ready="onGridReady"
            />
          </CCardBody>
          <CCardBody v-else>
            <gogo-loading />
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>

    <CModal size="lg" :title="formTitle" :show.sync="dialog">
      <CRow>
        <CCol col="2" class="pr-1">
          <CSelect
            label="Durum"
            :value.sync="editedItem.status"
            :options="[
              { value: '', label: 'Seçiniz' },
              { value: 'active', label: 'Aktif' },
              { value: 'passive', label: 'Pasif' },
            ]"
          />
        </CCol>
        <CCol col="6" class="pl-1">
          <CInput label="Kupon Adı" v-model="editedItem.name" />
        </CCol>
        <CCol col="4" class="pr-1">
          <CSelect
            label="Kupon Tipi"
            :value.sync="editedItem.type"
            :options="[
              { value: '', label: 'Seçiniz' },
              { value: 'multiple', label: 'Tek Kullanımlık Seri Üretim' },
              { value: 'single', label: 'Tek Kupon Çok Kez Kullanım' },
              { value: 'dynamic', label: 'Tek Kullanımlık Dinamik Üretim' },
            ]"
            v-on:change="
              editedItem.type == 'single'
                ? (editedItem.createdQuantity = 1)
                : (editedItem.usageLimit = 1)
            "
            :disabled="editedIndex !== -1"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol col="4" class="pr-1" v-if="editedItem.type !== 'dynamic'">
          <CInput
            label="Kupon Başına Kullanım Hakkı"
            type="number"
            v-model="editedItem.usageLimit"
            :readonly="editedItem.type == 'multiple'"
            :disabled="editedIndex !== -1"
          />
        </CCol>
        <CCol col="4" class="pr-1" v-if="editedItem.type !== 'dynamic'">
          <CInput
            label="Üretilecek Adet"
            type="number"
            v-model="editedItem.createdQuantity"
            :readonly="editedItem.type == 'single'"
            :disabled="editedIndex !== -1"
          />
        </CCol>
        <CCol col="4" class="pr-1">
          <CInput
            label="Kupon Kodu Ön Eki"
            v-model="editedItem.couponCode"
            :disabled="editedIndex !== -1"
          />
        </CCol>
        <CCol col="4" class="pr-1" v-if="editedItem.type == 'dynamic'">
          <CSelect
            label="Kazanım Senaryosu"
            :value.sync="editedItem.wintype"
            :options="[
              { value: '', label: 'Seçiniz' },
              { value: 'register', label: 'Üye Olduktan Sonra Ver' },
              { value: 'survey', label: 'Anket Tamamlandıktan Sonra Ver' },
              { value: 'last-search', label: 'Son Aramada Kupon' },
              { value: 'metropol-card', label: 'Metropol Kart' },
            ]"
            v-on:change="
              editedItem.type == 'single'
                ? (editedItem.createdQuantity = 1)
                : (editedItem.usageLimit = 1)
            "
            :disabled="editedIndex !== -1"
          />
        </CCol>
        <CCol col="4" class="pr-1" v-if="editedItem.type == 'dynamic'">
          <CInput
            label="Geçerli Olacak Gün Sayısı"
            v-model="editedItem.dayCount"
            :disabled="editedIndex !== -1"
          />
        </CCol>
      </CRow>
      <CCardBody v-if="!couponCodesLoading && editedIndex > -1">
        <ag-grid-vue
          style="width: 100%; height: calc(30vh)"
          class="ag-theme-alpine"
          :columnDefs="columnDefsCouponCode"
          :defaultColDef="defaultColDef"
          :rowData="couponCodeRowData"
        />
      </CCardBody>
      <CCardBody v-else-if="couponCodesLoading && editedIndex > -1">
        <gogo-loading style="width: 100%; height: calc(30vh)" />
      </CCardBody>
      <template #footer>
        <CButton
          class="download-coupon"
          size="lg"
          v-show="rowData[editedIndex].type == `multiple`"
          v-if="editedIndex > -1 || downLoadLoading"
          @click="downloadFile"
          :disabled="!rowData[editedIndex].isComplete || generalLoading"
          >{{ downLoadLinkText }}
          <font-awesome-icon
            :icon="['fas', 'circle-notch']"
            size="lg"
            class="spin mr-2"
            v-show="downLoadLoading && editedIndex > -1"
          />
        </CButton>
        <RoleProvider slug="COUPONS_DELETE">
          <div slot-scope="{ canItPass }">
            <CButton
              :disabled="!rowData[editedIndex].isComplete || generalLoading"
              size="lg"
              color="grey"
              @click="deleteItem()"
              v-if="editedIndex > -1 && canItPass"
              >Sil</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider :slug="'COUPONS_UPDATE'">
          <div slot-scope="{ canItPass }">
            <CButton
              size="lg"
              color="primary"
              @click="save()"
              v-html="'Düzenle'"
              class="white--text"
              v-if="canItPass && editedIndex > -1"
              :disabled="
                (!rowData[editedIndex].isComplete && editedIndex > -1) ||
                generalLoading
              "
            ></CButton>
          </div>
        </RoleProvider>
        <RoleProvider :slug="'COUPONS_CREATE'">
          <div slot-scope="{ canItPass }">
            <CButton
              size="lg"
              color="primary"
              @click="save()"
              v-html="'Ekle'"
              class="text-white"
              v-if="canItPass && !(editedIndex > -1)"
              :disabled="generalLoading"
            ></CButton>
          </div>
        </RoleProvider>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import Vue from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ckeditor from "ckeditor4-vue";
import { RoleProvider } from "../../provider";
import GogoLoading from "../../components/GogoLoading.vue";
import moment from "moment";

Vue.use(ckeditor);

export default {
  name: "Search",
  components: {
    vSelect,
    AgGridVue,
    RoleProvider,
    GogoLoading,
  },
  data() {
    return {
      gridLoading: false,
      couponCodesLoading: false,
      generalLoading: false,
      downLoadLoading: false,
      searchFilterInput: "",
      detailCellRendererParams: null,
      columnDefsCouponCode: [
        {
          field: "code",
          headerName: "Kupon Kodu",
        },
        {
          field: "total",
          headerName: "Üretilen",
        },
        {
          field: "remaining",
          headerName: "Kalan",
        },
      ],
      columnDefs: [
        {
          field: "name",
          headerName: "Kupon Adı",
        },
        {
          field: "status",
          cellRenderer: (params) => {
            if (params.value === "active") return "Aktif";
            else if (params.value === "passive") return "Pasif";
          },
          cellStyle: (params) => {
            if (params.value === "active")
              return {
                color: "#2eb85c",
                fontWeight: "bold",
              };
            else if (params.value === "passive")
              return {
                color: "#e55353",
                fontWeight: "bold",
              };
          },
          headerName: "Durum",
          width: 100,
          suppressSizeToFit: true,
        },
        {
          field: "type",
          headerName: "Tip",
          cellRenderer: (params) => {
            if (params.value === "single") return "Tek Kupon";
            else if (params.value === "multiple") return "Seri Kupon";
            else if (params.value === "dynamic") return "Dinamik Kupon";
          },
        },
        {
          field: "usageLimit",
          headerName: "Kullanım Hakkı",
        },
        {
          field: "createdQuantity",
          headerName: "Üretim Adedi",
        },
        {
          field: "newUserGift",
          headerName: "Kayıt Hediye Çeki",
          cellRenderer: (params) => {
            if (params.value) return "Aktif";
            else return "Pasif";
          },
          cellStyle: (params) => {
            if (params.value) {
              return {
                color: "#2eb85c",
                fontWeight: "bold",
              };
            } else {
              return {
                color: "#e55353",
                fontWeight: "bold",
              };
            }
          },
        },
        {
          field: "createdAt",
          headerName: "Oluşturma Tarihi",
          cellRenderer: (params) => {
            return moment(new Date(params.value)).format("DD.MM.YYYY - HH:mm");
          },
          sortable: true,
          sort: "desc",
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
      },
      defaultColDef: {},
      dialog: false,
      rowData: [],
      couponCodeRowData: [],

      vendorItems: [],
      editedIndex: -1,
      editedItem: {
        status: "active",
        name: "",
        type: "", // Tek  Kullanımlık
        wintype: "",
        dayCount: 0,
        createdQuantity: 0,
        usageLimit: 0,
        couponCode: "",
        newUserGift: false,
      },
      defaultItem: {
        status: "",
        name: "",
        type: "", // Tek  Kullanımlık
        createdQuantity: 0,
        usageLimit: 0,
        couponCode: "",
        newUserGift: false,
        createdAt: null,
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Yeni Kupon Oluştur" : "Kupon Düzenle";
    },

    downLoadLinkText() {
      if (this.downLoadLoading) return "İndiriliyor bekleyiniz...";
      return this.rowData[this.editedIndex]?.isComplete
        ? "Kuponları indir"
        : "Kuponlar kayıt ediliyor...(Bu işlem biraz zaman alabilir)";
    },
  },
  created() {
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          { field: "code", headerName: "Kupon Kodu" },
          { field: "total", headerName: "Üretilen" },
          { field: "remaining", headerName: "Kalan" },
        ],
        defaultColDef: {
          flex: 1,
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.coupons);
      },
    };

    this.getCampaign();
  },
  mounted() {},
  methods: {
    clickedDownload() {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "https://cdn.renticar.com/location/3b46ceb2-b11a-4207-99a4-9376dbaa7464.jpeg"
      );

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    async downloadFile() {
      try {
        this.downLoadLoading = true;
        window.location.href =
          process.env.VUE_APP_API_URL +
          "admin/coupon-download/" +
          this.rowData[this.editedIndex]._id;
      } catch (error) {
      } finally {
        this.downLoadLoading = false;
      }
    },

    async checkBoxEvent(event) {
      const checked = event.target.checked;
      const value = event.target.value;

      checked
        ? (this.editedItem.newUserGift = true)
        : (this.editedItem.newUserGift = false);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
    },

    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },

    async getCampaign() {
      try {
        this.gridLoading = true;
        let response = await axios.get(
          process.env.VUE_APP_API_URL + "admin/coupons"
        );
        this.rowData = response.data;
      } catch (e) {
        console.table(e.response);
      } finally {
        this.gridLoading = false;
      }
    },
    async getCouponCodes(id) {
      try {
        this.couponCodesLoading = true;
        let response = await axios.get(
          process.env.VUE_APP_API_URL + `admin/coupon-codes/${id}`
        );
        this.couponCodeRowData = response.data;
      } catch (e) {
        console.table(e.response);
      } finally {
        this.couponCodesLoading = false;
      }
    },
    editItem(params) {
      this.editedIndex = this.rowData.indexOf(params.node.data);
      this.editedItem = Object.assign({}, params.node.data);
      this.dialog = true;
      this.getCouponCodes(params.data._id);
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async deleteItem() {
      Vue.swal
        .fire({
          icon: "question",
          text: "Silmek istiyor musunuz?",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
          showCloseButton: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.generalLoading = true;
            try {
              const response = await axios.delete(
                process.env.VUE_APP_API_URL +
                  "admin/coupons/" +
                  this.rowData[this.editedIndex]._id
              );

              Vue.swal({
                icon: response.data.result,
                title: "Kupon Sil",
                html: response.data?.message?.message
                  ? response.data.message.message
                  : response.data.message,
                confirmButtonText: "Tamam",
              });
            } catch (error) {
            } finally {
              this.getCampaign();
              this.close();
              this.generalLoading = false;
            }
          }
        });
    },

    async save() {
      this.generalLoading = true;

      if (this.editedItem.wintype == "") delete this.editedItem.wintype;

      if (this.editedItem.type == "dynamic")
        this.editedItem.createdQuantity = 1;

      if (this.editedIndex > -1) {
        delete this.editedItem.createdAt;
        delete this.editedItem.updatedAt;
        delete this.editedItem.id;

        var response = await axios.put(
          process.env.VUE_APP_API_URL +
            "admin/coupons/" +
            this.rowData[this.editedIndex]._id,
          {
            status: this.editedItem.status,
            name: this.editedItem.name,
            newUserGift: this.editedItem.newUserGift,
          }
        );

        if (response.data.result == "success") {
          this.close();
        }
        Vue.swal({
          icon: response.data.result,
          title: "Kupon Düzenle",
          html: response.data.message,
          confirmButtonText: "Tamam",
        });
      } else {
        const { createdAt, ...newCouponParams } = this.editedItem;
        var response = await axios.post(
          process.env.VUE_APP_API_URL + "admin/coupons",
          newCouponParams
        );
        if (response.data.result == "success") {
          this.close();
        }
        Vue.swal({
          icon: response.data.result,
          title: "Kupon Ekle",
          html: response.data?.message?.message
            ? response.data.message.message
            : response.data.message,
          confirmButtonText: "Tamam",
        });
      }
      this.getCampaign();
      this.generalLoading = false;
    },
  },
};
</script>

<style scoped>
.download-coupon {
  position: absolute;
  left: 0;
  margin-left: 1rem;
}
</style>
